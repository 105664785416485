<template>
  <b-card
    class="h-100"
    no-body
    footer-class="px-0"
  >
    <template #header>
      <div
        ref="chatTalkHeader"
      >
        <div
          v-if="talkId"
          class="w-100 d-inline-flex justify-content-between mb-1"
        >
          <user-profile-link
            v-if="talkCustomer"
            :user="talkCustomer"
          >
            <b-avatar
              :src="talkCustomer.avatar"
              size="sm"
            />
            <strong class="ml-1">{{ talkCustomer.display_name }}</strong> (User Id: {{ talkCustomer.user_id }})
          </user-profile-link>
          <font-awesome-icon :icon="['fas', 'xmark']" @click="onClose" />
        </div>
        <strong class="small mb-0 d-inline-flex align-items-center justify-content-between w-100">
          <talk-link :talk="talkData">
            {{ $t('eChat_title', { talkId }) }}
          </talk-link>
        </strong>
      </div>
    </template>
    <template #footer>
      <chat-message-form
        v-if="talkId"
        :talk-id="talkId"
        @send="onSend"
      />
    </template>
    <chat-message-list
      :items="messages"
      :pagination="pagination"
      :busy="busy"
      :delete-busy="deleteBusy"
      @load="onLoad"
      @delete="onDelete"
    />
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ChatMessageList from './ChatMessageList'
import ChatMessageForm from './ChatMessageForm'

export default {
  name: 'ChatTalkBody',
  components: { ChatMessageForm, ChatMessageList },
  props: {
    talkId: {
      type: [Number, String],
      default: null,
      required: true
    }
  },
  data () {
    return {
      busy: false,
      deleteBusy: 0,
      allMessages: [],
      initialLeft: 0,
      initialTop: 0,
      currentLeft: 0,
      currentTop: 0,
      draggableHandle: null,
      mouseOver: false,
      isMobile: false
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      getChatOpen: 'chat/getChatOpen'
    }),
    ...mapFields({
      loadingEnable: 'loadingEnable'
    }),
    talkData () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        if (chat && chat.talk) {
          return chat.talk
        }
      }
      return {}
    },
    talkCustomer () {
      return this.talkData?.user
    },
    messages () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        if (chat && chat.messages) {
          return chat.messages
        }
      }
      return []
    },
    pagination () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        return (chat && chat.pagination) || {}
      }
      return false
    },
    draggableConf () {
      return this.isMobile
        ? {}
        : {
            handle: this.draggableHandle,
            resetInitialPos: true,
            initialPosition: {
              left: this.currentLeft || this.initialLeft,
              top: this.currentTop || this.initialTop
            }
          }
    },
    currentState () {
      const current = this.getChatOpen(this.talkId)
      return current?.state || false
    },
    talkStyle () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        if (chat) {
          return `z-index: ${1050 + parseInt(chat.lift)};`
        }
      }
      return `z-index: ${this.mouseOver ? 1051 : 1050};`
    }
  },
  watch: {
    currentState (n) {
      if (n) {
        this.prepareInitPos()
      }
    }
  },
  beforeDestroy () {
    this.$nextTick(() => {
      if (this.currentState) {
        this.toggleChat(this.talkId)
        this.currentLeft = 0
        this.currentTop = 0
      }
    })
  },
  beforeMount () {
    this.prepareInitPos()
  },
  mounted () {
    this.$nextTick(() => {
      this.draggableHandle = this.$refs.chatTalkHeader
      this.$forceUpdate()
    })
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError',
      loadChatMessages: 'chat/load',
      storeChatMessage: 'chat/store',
      reloadChat: 'chat/update',
      deleteChatMessages: 'chat/delete',
      toggleChat: 'chat/toggle',
      liftChat: 'chat/lift'
    }),
    onClose () {
      this.toggleChat(this.talkId)
      this.currentLeft = 0
      this.currentTop = 0
    },
    async onLoad (page) {
      try {
        this.busy = true
        this.loadingEnable = false
        if (this.talkId) {
          await this.loadChatMessages({
            talkId: this.talkId,
            page
          })
        }
      } finally {
        this.loadingEnable = true
        this.busy = false
      }
    },
    async onSend (payload) {
      const formData = new FormData()
      formData.append('message', payload.message)
      if (payload.media && payload.media.length) {
        formData.append('media', payload.media[0], 'media.jpg')
      }
      try {
        this.busy = true
        this.loadingEnable = false
        await this.storeChatMessage({
          talkId: this.talkId,
          message: formData
        })
        await this.loadChatMessages({
          talkId: this.talkId
        })
      } finally {
        this.loadingEnable = true
        this.busy = false
      }
    },
    onDelete (messageId) {
      this.$confirmDanger({
        title: this.$t('eDoc_list_deleteConfirmTitle'),
        body: this.$t('eDoc_list_deleteConfirmBody', { id: messageId }),
        okTitle: this.$t('eDoc_list_deleteConfirmOk'),
        cancelTitle: this.$t('eDoc_list_deleteConfirmCancel')
      })
        .then(async (value) => {
          if (value) {
            this.flushError()
            try {
              this.deleteBusy = messageId
              this.loadingEnable = false
              await this.deleteChatMessages({
                talkId: this.talkId,
                messageId
              })
            } finally {
              this.loadingEnable = true
              this.deleteBusy = 0
            }
          }
        })
    },
    onMoved (pos) {
      this.currentLeft = pos.currentDragPosition.left
      this.currentTop = pos.currentDragPosition.top
    },
    onMouseEnter () {
      this.mouseOver = true
      this.liftChat(this.talkId)
    },
    onMouseLeave () {
      this.mouseOver = false
    },
    prepareInitPos () {
      this.initialLeft = 55
      this.initialTop = 0
      // detect left
      Object.keys(this.$parent.$refs).forEach((ref) => {
        const el = this.$parent.$refs[ref]
        if (el && el.length && el[0].$refs && el[0].$refs.talk) {
          const rect = el[0].$refs.talk.getBoundingClientRect()
          if (this.initialLeft < rect.left + el[0].$refs.talk.clientWidth) {
            this.initialLeft = rect.left + el[0].$refs.talk.clientWidth
          }
          // console.log('initialLeft', el[0].$refs.talk, this.initialLeft, rect.left, el[0].$refs.talk.clientWidth)
        }
      })
      if (this.initialLeft > window.innerWidth) {
        this.initialLeft = 0
      }
      // detect top
      if (window) {
        const elHeight = window.innerHeight > 605 ? 605 : window.innerHeight
        this.initialTop = window.innerHeight - elHeight
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-talk {
  position: fixed;
  z-index: 1050;
  //bottom: 2px;
  //left: 2px;
  width: 335px;
  height: 605px;
  max-height: 100svh;
}
</style>
