<template>
  <div
    class="upload-media"
    :style="containerStyle"
  >
    <input
      ref="file_input"
      class="upload-media__input"
      type="file"
      name="image"
      accept="image/jpeg,image/png,image/bmp"
      @change="prepare"
    >
    <div class="upload-media__content">
      <a
        v-if="typeof fileExplorerObjectUrl !== 'undefined' && !compressingState"
        class="upload-media__cancel"
        @click="close"
      >
        <font-awesome-icon :icon="['fas', 'xmark']" size="2x" />
      </a>
      <div v-if="compressingState" class="d-flex justify-content-center align-items-center">
        <b-spinner label="Loading..." variant="dark" style="width:90px;height:90px;margin:15px" />
      </div>
      <img
        class="upload-media__preview"
        :src="fileExplorerObjectUrl"
        :style="previewStyle"
      >
      <div v-if="compressingError" class="text-center">
        <small v-shtml="compressingError" class="text-danger" />
      </div>
    </div>
  </div>
</template>

<script>
import Compressor from 'compressorjs'
// import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ChatUploadMedia',
  props: {
    talkId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      compressingState: false,
      compressingError: '',
      compressingStatus: '',
      previewWidth: 180,
      previewHeight: 0,
      containerMaxHeight: 330
    }
  },
  computed: {
    ...mapGetters({
      getChatOpen: 'chat/getChatOpen'
    }),
    // ...mapFields('chat', {
    //   fileExplorerState: 'fileExplorerState',
    //   fileExplorerObjectUrl: 'fileExplorerObjectUrl'
    // }),
    containerStyle () {
      if (this.compressingState) {
        return 'height:120px'
      }
      if (this.fileExplorerObjectUrl !== undefined) {
        return 'height:' + this.containerMaxHeight + 'px'
      }
      return ''
    },
    previewStyle () {
      return this.fileExplorerObjectUrl === undefined ? 'display:none' : ''
    },
    hasMessages () {
      return this.compressingError !== '' || this.compressingStatus !== ''
    },
    fileExplorerState () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        return chat && chat.fileExplorerState
      }
      return false
    },
    fileExplorerObjectUrl () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        return chat && chat.fileExplorerObjectUrl
      }
      return undefined
    }
  },
  watch: {
    fileExplorerState (value) {
      if (value) {
        this.$refs.file_input.click()
        this.onFileExplorerClosed(this.talkId)
      }
    }
  },
  methods: {
    ...mapActions('chat', {
      onFileExplorerClosed: 'onFileExplorerClosed',
      setFileExplorerState: 'setFileExplorerState',
      setFileExplorerObjectUrl: 'setFileExplorerObjectUrl'
    }),
    close () {
      this.setFileExplorerObjectUrl({ talkId: this.talkId, value: undefined })
      this.compressingState = false
      this.compressingError = ''
      this.compressingStatus = ''
      this.previewHeight = 0
      this.$refs.file_input.value = null
      this.$emit('close')
    },
    prepare (e) {
      this.compressingError = ''
      this.compressingStatus = ''
      this.previewHeight = 300
      const file = e.target.files.length && e.target.files[0]
      this.$refs.file_input.value = null
      if (file) {
        this.setFileExplorerObjectUrl({ talkId: this.talkId, value: undefined })
        this.compressingState = true
        // eslint-disable-next-line no-new
        new Promise((resolve, reject) => {
          // eslint-disable-next-line no-new
          new Compressor(file, {
            convertSize: 1048576,
            quality: 0.87,
            maxWidth: 1280,
            maxHeight: 1280,
            success: resolve,
            error: reject
          })
        })
          .then((result) => {
            if (file.size !== result.size) {
              this.compressingStatus = `compressed from ${this.displaySize(file.size)} to ${this.displaySize(result.size)}`
            }
            this.compressingState = false
            this.setFileExplorerObjectUrl({ talkId: this.talkId, value: URL.createObjectURL(result) })
            this.$emit('ready', [result])
          })
          .catch((error) => {
            this.compressingState = false
            this.compressingError = error.message
            setTimeout(() => {
              this.close()
            }, 3000)
          })
          .finally(() => {
            this.compressingState = false
          })
      } else {
        this.close()
      }
    },
    displaySize (rawSize) {
      const fSExt = ['Bytes', 'KB', 'MB', 'GB']
      let i = 0
      let size = rawSize
      while (size > 900) {
        size /= 1024
        i++
      }
      return (Math.round(size * 100) / 100) + ' ' + fSExt[i]
    }
  }
}
</script>

<style scoped lang="scss">
.upload-media {
  background-color:white;
  overflow: hidden;
  width: 100%;
  transition: height 0.3s ease-out;
  height:0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  position: relative;
  .upload-media__input {
    display: none;
  }
  .upload-media__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
  }
  .upload-media__cancel {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    color: theme-color(dark);
  }
  .upload-media__preview {
    max-height:300px;
    max-width:300px;
    width:auto;
    border: none;
    margin:15px;
  }
}
</style>
