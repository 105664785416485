<template>
  <div
    v-if="isMobile"
    v-show="talksListState"
    class="chat-talks"
    :class="{'nav-expanded': sidebarState}"
    :style="listStyle"
  >
    <div
      class="chat-talks__header d-flex align-items-center justify-content-between px-2 py-2"
      style="background-color: rgba(255,255,255,.05)"
    >
      <strong class="chat-talks__header__title d-block text-center h5 pl-2 large">
        {{ $t(listType === 'unread' ? 'eChat_listLatestUnreadTitle' : 'eChat_listLatestTitle') }}
        <small>{{ displayLoadedCount }}</small>
      </strong>
      <div class="d-flex flex-row align-items-center justify-content-center py-1">
        <spinner v-if="typeBusyState" class="py-1 m-0" light />
        <b-btn
          v-for="key in Object.keys(tabs)"
          v-else
          :key="key"
          size="sm"
          class="mr-2"
          :variant="listType === key ? 'light' : 'outline-light'"
          :disabled="listType === key"
          @click.prevent.stop="onLoad(key)"
        >
          {{ tabs[key] }}
        </b-btn>
        <b-link
          class="chat-talks__header__close p-2"
          @click="onClose"
        >
          <font-awesome-icon :icon="['fas', 'xmark']" size="lg" />
        </b-link>
      </div>
    </div>
    <div
      class="chat-talks__body"
      :style="listBodyStyle"
    >
      <ul class="list-unstyled m-0">
        <li
          v-for="(talk, i) in chatList"
          :key="i"
          class="chat-talks__item py-2 px-3 d-flex align-items-center"
        >
          <chat-talk-item
            :talk="talk"
            @click="onClick(talk.id)"
          />
        </li>
        <li v-if="nextListPage > 0 && nextListPage !== totalListPages" class="py-2 text-center load-more">
          <spinner v-if="listBusyState" id="load-more" />
          <b-btn
            v-else
            id="load-more"
            :disabled="listBusyState"
            size="sm"
            variant="outline-secondary"
            @click="loadNext"
          >
            {{ $t('eChat_listLoadNext', { size: listPageSize }) }}
          </b-btn>
        </li>
      </ul>
    </div>
  </div>
  <div
    v-else
    v-show="talksListState"
    v-click-outside="onClose"
    class="chat-talks"
    :class="{'nav-expanded': sidebarState}"
    :style="listStyle"
  >
    <div
      class="chat-talks__header d-flex align-items-center justify-content-between px-2 py-2"
      style="background-color: rgba(255,255,255,.05)"
    >
      <strong class="chat-talks__header__title d-block text-center h5 pl-2 large">
        {{ $t(listType === 'unread' ? 'eChat_listLatestUnreadTitle' : 'eChat_listLatestTitle') }}
        <small>{{ displayLoadedCount }}</small>
      </strong>
      <div class="d-flex flex-row align-items-center justify-content-center py-1">
        <spinner v-if="typeBusyState" class="py-1 m-0" light />
        <b-btn
          v-for="key in Object.keys(tabs)"
          v-else
          :key="key"
          size="sm"
          class="mr-2"
          :variant="listType === key ? 'light' : 'outline-light'"
          :disabled="listType === key"
          @click.prevent.stop="onLoad(key)"
        >
          {{ tabs[key] }}
        </b-btn>
        <b-link
          class="chat-talks__header__close p-2"
          @click="onClose"
        >
          <font-awesome-icon :icon="['fas', 'xmark']" size="lg" />
        </b-link>
      </div>
    </div>
    <div
      class="chat-talks__body"
      :style="listBodyStyle"
    >
      <ul class="list-unstyled m-0">
        <li
          v-for="(talk, i) in chatList"
          :key="i"
          class="chat-talks__item py-2 px-3 d-flex align-items-center"
        >
          <chat-talk-item
            :talk="talk"
            @click="onClick(talk.id)"
          />
        </li>
        <li v-if="nextListPage > 0 && nextListPage !== totalListPages" class="py-2 text-center load-more">
          <spinner v-if="listBusyState" id="load-more" />
          <b-btn
            v-else
            id="load-more"
            :disabled="listBusyState"
            size="sm"
            variant="outline-secondary"
            @click="loadNext"
          >
            {{ $t('eChat_listLoadNext', { size: listPageSize }) }}
          </b-btn>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ChatTalkItem from './ChatTalkItem'

export default {
  name: 'ChatTalks',
  components: { ChatTalkItem },
  data () {
    return {
      listBusyState: false,
      typeBusyState: false,
      tabs: {
        all: 'All',
        unread: 'Unread'
      }
    }
  },
  computed: {
    ...mapGetters({
      talksListState: 'chat/talksListState',
      list: 'chat/list',
      totalListItems: 'chat/totalListItems',
      nextListPage: 'chat/nextListPage',
      totalListPages: 'chat/totalListPages',
      listPageSize: 'chat/listPageSize',
      listType: 'chat/listType',
      chatLift: 'chat/lift',
      deviceInfo: 'device'
    }),
    ...mapFields({
      sidebarState: 'layout.sidebarState'
    }),
    ...mapFields({
      loadingEnable: 'loadingEnable'
    }),
    chatList () {
      return this.list?.map(i => i.talk)
    },
    displayLoadedCount () {
      return ` (${this.list.length} / ${this.totalListItems})`
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || this.deviceInfo?.type === 'tablet' || false
    },
    listStyle () {
      let style = ''
      if (this.talksListState) {
        if (!this.chatLift) {
          style += 'z-index: 1051; '
        } else {
          style += 'z-index: ' + (1051 + this.chatLift) + '; '
        }
        if (this.isMobile) {
          style += 'max-height: 100svh; height: 100svh; '
        }
      }
      return style
    },
    listBodyStyle () {
      let style = ''
      if (this.talksListState && this.isMobile) {
        style += 'max-height: calc(100svh - 65px); height: calc(100svh - 65px); '
      }
      return style
    }
  },
  methods: {
    ...mapActions({
      hideTalksList: 'chat/hideTalksList',
      loadTalks: 'chat/loadTalks',
      setTalksType: 'chat/setTalksType',
      setLoadingEnable: 'setLoadingEnable'
    }),
    onClick (talkId) {
      this.$store.dispatch('chat/load', { talkId })
      this.hideTalksList()
    },
    onClose (e) {
      const el = e.target
      const elId = el?.id || ''
      if (!['load-more'].includes(elId)) {
        this.hideTalksList()
      }
    },
    async loadNext () {
      try {
        this.listBusyState = true
        this.setLoadingEnable(false)
        await this.loadTalks(this.nextListPage)
        this.setLoadingEnable(true)
      } finally {
        this.listBusyState = false
      }
    },
    async onLoad (key) {
      try {
        this.typeBusyState = true
        this.setLoadingEnable(false)
        await this.setTalksType(key)
        await this.loadTalks(this.nextListPage)
        this.setLoadingEnable(true)
      } finally {
        this.typeBusyState = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
